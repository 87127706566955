/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			validateNumber: function (event) {
				if (event.keyCode === 46 || event.keyCode === 8 || event.keyCode === 9 || event.keyCode === 27 || event.keyCode === 13 ||
					 // Allow: Ctrl+A
					 (event.keyCode === 65 && event.ctrlKey === true) ||
					 // Allow: home, end, left, right
					 (event.keyCode >= 35 && event.keyCode <= 39)) {
					 // let it happen, don't do anything
					 return;
				 }
				 else {
					 // Ensure that it is a number and stop the keypress
					 if (event.shiftKey || (event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105)) {
						 event.preventDefault();
					 }
				 }
			},
			init: function() {
				var _this = this;
				$(".onlyNumbers").keydown( _this.validateNumber );
				// JavaScript to be fired on all pages
				$('.openavailability a').magnificPopup({
					type: 'inline',
					midClick: true,
					fixedContentPos: true,
					fixedBgPos: true,
					callbacks: {
					}
				});
				$( document.body ).on( 'updated_checkout', function () {
					$('a[href="#politics"]').magnificPopup({
						type:'inline',
						midClick: true,
						fixedContentPos: true,
						fixedBgPos: true,
					});	
				} );
				$('a[href="#politics"]').magnificPopup({
					type:'inline',
					midClick: true,
					fixedContentPos: true,
					fixedBgPos: true,
				});
				/*items: {
					src: '##bs-navbar'
				},*/
				/*
				$('.navbar-toggle').magnificPopup({
					type: 'inline',
					midClick: true,
					fixedContentPos: true,
					fixedBgPos: true,
					callbacks: {
						resize: function() {
							if ( $(window).width() >= 1360  ) {
								$.magnificPopup.close();
							}
						},
					}
				});
				*/
				$( '#change_city' ).select2({
					theme: "bootstrap",
					minimumResultsForSearch: Infinity
				});
				$('#change_city').change( function ( ) {
					window.location = $(this).val();
				} );
			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},
		// Home page
		'home': {
			init: function() {
				// JavaScript to be fired on the home page
			},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		// About us page, note the change from about-us to about_us.
		'about_us': {
			init: function() {
				// JavaScript to be fired on the about us page
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
